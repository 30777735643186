import { distinctUntilChanged, map } from 'rxjs/operators';
import { Component, OnInit, PLATFORM_ID, inject, signal } from '@angular/core';
import { filter, Subject, switchMap, tap } from 'rxjs';
import { UserService } from '@origin8-web/core-ui/login/providers';
import { Store } from '@ngrx/store';
import { EnableNotificationBannerComponent, NotificationService } from '@origin8-web/core-ui/notification';
import { priorityLeadsActions } from '../core/store/actions/priority-leads.actions';
import { BrowserExtApiService } from '@origin8-web/core-ui/browser-ext-api';
import { UserStore } from '../core/store/user/user.store';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { ImpersonationWidgetComponent } from '@origin8-web/core-ui/login/components';
import { LiveStatusStore } from '../core/store/live-status/live-status.store';
import { isPlatformBrowser } from '@angular/common';
import { PROTEC8_PATH } from '../app.interface';
import { withoutStartingSlash } from '@origin8-web/o8-utils/format';

@Component({
  selector: 'p8-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NavbarComponent, EnableNotificationBannerComponent, ImpersonationWidgetComponent],
})
export class AppComponent implements OnInit {
  readonly userStore = inject(UserStore);
  readonly liveStatusStore = inject(LiveStatusStore);
  readonly userService = inject(UserService);
  readonly store = inject(Store);
  readonly notificationService = inject(NotificationService);
  readonly browserExtApiService = inject(BrowserExtApiService);
  readonly platformId = inject(PLATFORM_ID);
  readonly router = inject(Router);

  readonly unsubscribe$ = new Subject<void>();
  readonly hasInit = signal<boolean>(false);
  readonly hideNavbar = signal<boolean>(false);

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router.url),
        distinctUntilChanged(),
        tap((url) => {
          this.hideNavbar.set(withoutStartingSlash(url) === PROTEC8_PATH.INCOMING_WARM_TRANSFER);
        }),
      )
      .subscribe();
    this.userService
      .getIsLoggedIn$()
      .pipe(
        filter((loggedIn) => !!loggedIn),
        switchMap(() => this.userService.getSelfOrImpersonatedUser$()),
        distinctUntilChanged(),
        tap((userInfo) => {
          this.userStore.setUserInfo(userInfo);
          this.initiateBackgroundEffects();
        }),
      )
      .subscribe();
    this.notificationService.requestPermission();
    this.browserExtApiService.checkExtensionIsInstalledAndDisplayErrorMessageIfNot().subscribe();
    if (isPlatformBrowser(this.platformId)) {
      this.liveStatusStore.loadLiveStatuses();
      this.liveStatusStore.loadRepresentativesProfiles();
    }
  }

  initiateBackgroundEffects() {
    if (!this.hasInit()) {
      this.store.dispatch(priorityLeadsActions.setActiveState({ active: true }));
      this.hasInit.set(true);
    }
  }
}
